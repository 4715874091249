<template>
    <b-container v-if="missions && rating" class="ratings">
        <div class="ratings__info">
            <div>Рейтинг</div>
            <div>Ваш общий рейтинг: {{ overalRating }}</div>
            <div>Ваш рейтинг текущей миссии: {{ currentUserRating }}</div>
            <div>Ваши байты: {{ overalScore }}</div>
        </div>

        <div class="ratings__pills">
            <div
                v-for="(pill, index) in category"
                :key="pill.label"
                :class="{ active: pill.isActive }"
                class="al-pill"
                @click="activeIndexPill = index"
            >
                {{ pill.title }}
            </div>
        </div>

        <div class="ratings__table">
            <a-l-rating-talbe v-if="rating" :rating="rating" />
        </div>
    </b-container>
</template>

<script>
import ALRatingTalbe from '@/components/ALRatingTable.vue';
import RatingApi from '@/services/rating';

export default {
    name: 'Ratings',
    components: { ALRatingTalbe },
    data() {
        return {
            rating: null,
            activeIndexPill: 0,
            overalRating: '',
            overalScore: '',
        };
    },

    async created() {
        this.rating = await RatingApi.getCommonRating().catch(console.log);
        if (this.rating) {
            const userRatingData = this.rating?.find(i => i.you);
            if (userRatingData) {
                this.overalRating = userRatingData.rating;
                this.overalScore = userRatingData.total_score;
            }

            if (!this.missions) {
                await this.$store.dispatch('missions/fetchMissions');
            }
        }
    },

    methods: {},

    computed: {
        missions() {
            return this.$store.state.missions.missions;
        },
        category() {
            return [{ title: 'Общий рейтинг' }, ...this.$store.getters['missions/missionsWithoutSecret']].map(
                (i, index) => {
                    i.isActive = index === this.activeIndexPill;
                    return i;
                }
            );
        },
        currentUserRating() {
            return this.rating?.find(i => i.you)?.rating || '-';
        },
    },

    watch: {
        async activeIndexPill(index) {
            if (index === 0) {
                this.rating = await RatingApi.getCommonRating().catch(console.log);
            } else {
                this.rating = await RatingApi.getRatingById(this.category[index].id).catch(console.log);
            }
        },
    },
};
</script>
