<template>
    <div class="ai-rating-table">
        <b-table
            no-border-collapse
            borderless
            :items="computedRows"
            :fields="tableFields"
            table-class="rating-table"
            thead-class="rating-table__table-head"
            tbody-class="rating-table__table-body"
            thead-tr-class="table-head__head-row"
            ref="ratingTable"
            primary-key="rating"
        >
            <template #cell(empty)="data">
                <span v-if="data.item.you"
                    ><svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22 0L27.1638 15.8926H43.8743L30.3552 25.7148L35.5191 41.6074L22 31.7852L8.48094 41.6074L13.6448 25.7148L0.1257 15.8926H16.8362L22 0Z"
                            fill="#DC4433"
                        />
                    </svg>
                </span>
            </template>

            <template v-if="!showAll && rating.length" #custom-foot>
                <td colspan="4">
                    <al-btn variant="primary" class="al-navbar__button" @click="showAllRows">
                        Показать полностью
                    </al-btn>
                </td>
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AlBtn from '@/components/AlBtn';

export default {
    name: 'ALRatingTalbe',

    components: { AlBtn },

    props: {
        rating: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            showAll: false,
        };
    },
    methods: {
        showAllRows() {
            this.showAll = true;
        },
    },

    computed: {
        ...mapGetters({ firstName: 'auth/getFirstName', lastName: 'auth/getLastName' }),
        tableFields() {
            return [
                {
                    key: 'empty',
                    label: '',
                    thClass: 'head-row__cell',
                    tdClass: 'body-row__cell',
                },
                {
                    key: 'rating',
                    label: 'Место',
                    thClass: 'head-row__cell',
                    tdClass: 'body-row__cell',
                },
                {
                    key: 'full_name',
                    label: 'Имя',
                    thClass: 'head-row__cell',
                    tdClass: 'body-row__cell body-row__cell__user_name',
                },
                {
                    key: 'total_score',
                    label: this.$mq === 'xs' ? 'Байты' : 'Общие Байты',
                    thClass: 'head-row__cell',
                    tdClass: 'body-row__cell',
                },
            ];
        },
        computedRows() {
            if (this.showAll) {
                return this.rating;
            }
            const filler = { rating: '...', full_name: '...', total_score: '...' };

            // if start position
            if (this.userRating <= 6) {
                return [...this.rating.slice(0, 6), filler];
            }
            // if last position
            if (this.userRating === this.rating.length) {
                return [
                    ...this.rating.slice(0, 3),
                    filler,
                    ...this.rating.slice(this.userRating - 3, this.rating.length),
                ];
            }
            // if middle position
            return [...this.rating.slice(0, 3), filler, ...this.rating.slice(this.userRating - 2, this.userRating + 1)];
        },
        userRating() {
            return this.rating.find(i => i.you)?.rating || 0;
        },
    },

    watch: {
        rating: {
            deep: true,
            handler() {
                this.showAll = false;
            },
        },
    },
};
</script>
