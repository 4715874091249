import axios from 'axios';

class RatingApi {
    getCommonRating() {
        return axios.get('/rating').then(({ data }) => data);
    }
    getRatingById(id) {
        return axios.get(`/rating?event_group_id=${id}`).then(({ data }) => data);
    }
}

export default new RatingApi();
